<template>
  <div class="sm:px-sm sm:pb-sm md:px-md md:pb-md">
    <CampaignConfigurator
      v-if="!error"
      :campaign="campaign"
      :line-item="data?.lineItem"
      is-in-edit-dialog
      @add-to-cart="
        (items, quantity) => {
          dialog.close(items, quantity);
        }
      "
    />
    <LoadingError v-else />
  </div>
</template>
<script setup lang="ts">
import useEditCampaignDialog from '~/composables/dialogs/useEditCampaignDialog';
import CampaignConfigurator from '@/components/page/product/actions/components/campaignConfigurator/campaignConfigurator.vue';
import { useCachedGet } from '~/composables/dataFetching/genericFetchers';
import type { Result } from '~/server/api/[site]/shop/campaign/[id].get';
import { LoadingError } from '@/complib';
import { handleLoadingError } from '~/utils/handleLoadingError';

const dialog = useEditCampaignDialog();

const data = dialog.getData();
const error = ref(false);

let campaign = data.campaign;

if (!campaign && data.lineItem) {
  try {
    const result = await useCachedGet<Result>(
      `/api/${useSiteIdent()}/shop/campaign/${data.lineItem.campaign.id}`,
    );
    campaign = result.data.value;
  } catch (e) {
    error.value = true;
    handleLoadingError(e);
  }
}
</script>
